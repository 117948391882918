const routes = {
  path: '/',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'home' */ './Homenew'),
    },
    // {
    //   path: '/home',
    //   load: () => import(/* webpackChunkName: 'home' */ './Homenew'),
    // },
    {
      path: '/contact-us',
      load: () => import(/* webpackChunkName: 'contact' */ './contact'),
    },
    // {
    //   path: '/seo-services-company',
    //   load: () => import(/* webpackChunkName: 'contact' */ './seo'),
    // },
    {
      path: '/seo',
      // action: () => {
      //   console.log('checking child routes for /posts')
      //   import
      // },
      load: () => import(/* webpackChunkName: 'contact' */ './seo'),
    },
    {
      path: '/affiliate-marketing',
      load: () =>
        import(/* webpackChunkName: 'contact' */ './affiliate_marketingnew'),
    },
    {
      path: '/b2b-membership',
      load: () => import(/* webpackChunkName: 'aso' */ './b2bmembership'),
    },
    {
      path: '/bounty',
      load: () => import(/* webpackChunkName: 'aso' */ './bountryprogram'),
    },
    {
      path: '/business-verticals',
      load: () => import(/* webpackChunkName: 'aso' */ './businessVerticals'),
    },
    {
      path: '/payment/:amount',
      load: () => import(/* webpackChunkName: 'aso' */ './payment'),
    },
    {
      path: '/how-we-work',
      load: () => import(/* webpackChunkName: 'aso' */ './register'),
    },
    {
      path: '/careers',
      load: () => import(/* webpackChunkName: 'aso' */ './careernew'),
    },
    {
      path: '/changepassword/:id',
      load: () => import(/* webpackChunkName: 'aso' */ './ChangePassword'),
    },
    {
      path: '/college-membership',
      load: () => import(/* webpackChunkName: 'aso' */ './collegeMembership'),
    },
    {
      path: '/email-marketing',
      load: () => import(/* webpackChunkName: 'aso' */ './EmailServicenew'),
    },
    {
      path: '/fb-advertising',
      load: () => import(/* webpackChunkName: 'aso' */ './FacebookAd'),
    },
    {
      path: '/faq',
      load: () => import(/* webpackChunkName: 'aso' */ './faq'),
    },
    {
      path: '/site-audit',
      load: () => import(/* webpackChunkName: 'aso' */ './freetrial'),
    },
    {
      path: '/free-seo-site-audit',
      load: () => import(/* webpackChunkName: 'aso' */ './site_audit_2'),
    },
    {
      path: '/grievance',
      load: () => import(/* webpackChunkName: 'aso' */ './grivence'),
    },
    {
      path: '/logo-design-company-in-coimbatore',
      load: () => import(/* webpackChunkName: 'aso' */ './LogoDesignnew'),
    },

    {
      path: '/native-advertising',
      load: () =>
        import(/* webpackChunkName: 'aso' */ './native_advertisingnew'),
    },
    {
      path: '/privacy-policy',
      load: () => import(/* webpackChunkName: 'aso' */ './privacyPolicy'),
    },
    {
      path: '/safety',
      load: () => import(/* webpackChunkName: 'aso' */ './safety'),
    },
    {
      path: '/social-media-marketing-company',
      load: () => import(/* webpackChunkName: 'aso' */ './smonew'),
    },
    {
      path: '/terms-and-conditions',
      load: () => import(/* webpackChunkName: 'aso' */ './TermsAndConditions'),
    },
    {
      path: '/giveaway-disclaimer',
      load: () => import(/* webpackChunkName: 'aso' */ './GiveawayDisclaimer'),
    },
    {
      path: '/ui-ux-design-company',
      load: () => import(/* webpackChunkName: 'aso' */ './uiService'),
    },
    {
      path: '/web-development-company-in-coimbatore',
      load: () => import(/* webpackChunkName: 'aso' */ './webDevelopmentnew'),
    },
    {
      path: '/safety',
      load: () => import(/* webpackChunkName: 'aso' */ './safety'),
    },
    {
      path: '/about-us',
      load: () => import(/* webpackChunkName: 'about' */ './about'),
    },
    {
      path: '/blog',
      load: () => import(/* webpackChunkName: 'about' */ './blognew'),
    },
    {
      path: '/blog/:b',
      load: () => import(/* webpackChunkName: 'about' */ './blogPages/index'),
    },
    {
      path: '/free-trial',
      load: () => import(/* webpackChunkName: 'about' */ './freetrialregister'),
    },
    {
      path: '/ppc-services-company',
      load: () => import(/* webpackChunkName: 'about' */ './ppcnew'),
    },
    {
      path: '/new-page',
      load: () => import(/* webpackChunkName: 'about' */ './new-pages'),
    },
    {
      path: '/app-store-optimization-company',
      load: () => import(/* webpackChunkName: 'about' */ './asonew'),
    },
    {
      path: '/thank-you',
      load: () => import(/* webpackChunkName: 'about' */ './thankyou'),
    },
    {
      path: '/digital-marketing-company',
      load: () => import('./digital_marketing'),
    },
    {
      path: '/nft-marketing-agency',
      load: () => import('./nft'),
    },
    {
      path: '/video-marketing',
      load: () => import('./Video'),
    },
    // {
    //   path: '/marketing-sales',
    //   load: () => import('./marketing_sales')
    // },
    {
      path: '/marketing-sales',
      load: () => import(/* webpackChunkName: 'aso' */ './marketing_sales'),
    },
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();
    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';
    route.keywords = route.keywords || '';
    route.Canonical = route.Canonical || '';
    route.ogtitle = route.ogtitle || '';
    route.ogsitename = route.ogsitename || '';
    route.ogurl = route.ogurl || '';
    route.ogdescription = route.ogdescription || '';
    route.ogtype = route.ogtype || '';
    route.ogimage = route.ogimage || '';
    route.twittercard = route.twittercard || '';
    route.twittersite = route.twittersite || '';
    route.twittertitle = route.twittertitle || '';
    route.twitterdescription = route.twitterdescription || '';
    route.twitterimage = route.twitterimage || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
