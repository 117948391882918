import UniversalRouter from 'universal-router';
import routes from './routes';

/*
  Check the String (URL) is in Uppercase or not
  @string - pathname
  return boolean
*/
const checkUppercase = pathname => {
  let i = 0;
  let character = '';
  let isUpperCase = false;
  while (i <= pathname.length) {
    character = pathname.charAt(i);
    if (character != character.toLowerCase()) {
      console.log('Character: ', character, character.toLowerCase());
      isUpperCase = true;
    }
    i++;
  }
  return isUpperCase;
};

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (context.pathname != '/') {
      const lastCharacter = context.pathname.charAt(context.pathname.length - 1);
      if (lastCharacter.includes('/')) {
        return { redirect: context.pathname.slice(0, -1) }
      }
    }
    
    // if (con)
    if(context.pathname == '/ppc') {
      return { redirect : "/ppc-services-company" }
    }

    if(context.pathname == '/smo') {
      return { redirect : "/social-media-marketing-company" }
    }
    if(context.pathname == '/web-development') {
      return { redirect : "/web-development-company-in-coimbatore" }
    }
    if(context.pathname == '/ui-ux-design') {
      return { redirect : "/ui-ux-design-company" }
    }

    if(context.pathname == '/logo-designing') {
      return { redirect : "/logo-design-company-in-coimbatore" }
    }

    if (
      checkUppercase(context.pathname) &&
      !context.pathname.includes('/blog')
    ) {
      return { redirect: context.pathname.toLowerCase() };
    } else {
      // console.log('Elses')
      if (typeof context.route.load === 'function') {
        return context.route
          .load()
          .then(action => action.default(context, params));
      }
      if (typeof context.route.action === 'function') {
        return context.route.action(context, params);
        
      }
    }
    return undefined;
  },
});
