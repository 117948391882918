const Filter = (state = {}, action) => {
  switch (action.type) {
    case 'GET_TOKEN':
      return {
        ...state,
        token: action.payload,
      };
    case 'GET_REFRESH' :
      return {
        ...state,
        isValid: action.payload.isValid,
        isRefresh: action.payload.isRefresh,
        access: action.payload.access
      }
    case 'GET_EMAIL_BING':
      return {
        ...state,
        bing_email: action.payload,
      };
    case 'GET_EMAIL_DOMAIN':
      return {
        ...state,
        domain_email: action.payload,
      };
    case 'GET_SOURCE':
      return {
        ...state,
        source: action.payload,
      };
    case 'GET_MAIL_VERIFIER':
      return {
        ...state,
        mail: action.payload.maillist,
        isCompleted: action.payload.isCompleted,
      };
    case 'GET_EMAIL_VERIFIER':
      return {
        ...state,
        mailVerifier: action.payload,
      };
    case 'GET_EMAIL_VERIFIER_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'GET_EMAIL_FINDER':
      return {
        ...state,
        mailFInder: action.payload,
      };
    case 'GET_BULK_EMAIL_FINDER':
      return {
        ...state,
        bulk_email: action.payload,
      };
    case 'GET_BULK_EMAIL_FINDER_ERROR':
      return {
        ...state,
        bulk_error: action.payload,
      };
    case 'GET_DOMAIN_AUTOFILL':
      return {
        ...state,
        domain_fill: action.payload,
      };
    case 'GET_EMAIL_AUTOFILL':
      return {
        ...state,
        email_fill: action.payload,
      };
    default:
      return state;
  }
};

export default Filter;

// changepassword
//
